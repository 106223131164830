import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm, Controller, Resolver } from "react-hook-form";
import TextAreaField from "../../../components/forms/TextAreaField";
import Button from "../../../components/general/Button";
import { color_colors_ocean, color_defaults_white, color_shades_dark, color_shades_darker, dark_red, text_blue } from "../../../constants/colors";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import StyledDropdown from "../../applicationSettings/components/customAssistantConfigurations/StyledDropdown";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import useWindowSize from "../../../hooks/UseWindowSize";
import { FoodOrderingGenAIModelSettingId, FoodOrderingGenAIPromptOverrideSettingId } from "../../../constants/featureFlagSettingIds";
import { GenAIModels } from "../../../constants/genAiModels";
import { de } from "date-fns/locale";
import ContentCollapsingSection from "../../../components/structure/ContentCollapsingSection";
import IconTooltip from "../../../components/general/IconTooltip";
import { defaultWhite } from "../../../constants/customAssistantConfigurationStyles/customAssistantConfigurationsColors";
import { css } from '@emotion/css';
import CheckboxField from "../../../components/forms/CheckboxField";

interface FeatureSettingProps {
    isLoading: boolean;
    applicationId: string;
    handleSave: (model: UpdateFeatureFlagSettings) => void;
    appContainer: ApplicationContainer
};

interface FormInputs {
    systemPrompt: string;
    featureFlagId: string;
}

const FeatureSettings: React.FC<FeatureSettingProps> = (props) => {
    const resolver: Resolver<FormInputs> = async (values) => {
        const errors: any = {};
        return {
            values: values,
            errors,
        };
    };

    const [featureFlagSettings, setFeatureFlagSettings] = useState<FeatureFlagSettingModel[]>([]);

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<FormInputs>({
        resolver,
        mode: "onChange",
        defaultValues: {
            systemPrompt: "",
            featureFlagId: ""
        }
    });
    const windowSize = useWindowSize();
    useEffect(() => {
        props.appContainer.loadFeatureFlags().then(() => {
            const featureFlags = props.appContainer?.state?.currentApp.featureFlags;
            if (featureFlags?.length > 0) {
                let defaultFlag = featureFlags.find(f => f.name == "Generative Assistant Ordering")
                if (!defaultFlag)
                    defaultFlag = featureFlags[0];
                setValue('featureFlagId', defaultFlag.id, {
                    shouldValidate: true,
                    shouldDirty: true
                });
            }
        });
    }, []);

    useEffect(() => {
        const selectedFlag = props?.appContainer?.state?.featureFlags?.find(f => f.id === getValues().featureFlagId);
        if (selectedFlag?.featureFlagSettings) {
            const applicationFeatureFlag = props.appContainer?.state?.currentApp.featureFlags?.find(f => f.id === getValues().featureFlagId);
            for (const featureFlagSetting of selectedFlag?.featureFlagSettings) {
                featureFlagSetting.value = applicationFeatureFlag.featureFlagSettings?.find(fs => fs.id === featureFlagSetting.id)?.value;
            }
            setFeatureFlagSettings([...selectedFlag.featureFlagSettings]);
        }
    }, [getValues().featureFlagId, props?.appContainer?.state?.featureFlags]);

    const handleFeatureFlagChange = (value: string) => {
        const featureFlags = props.appContainer?.state?.currentApp.featureFlags;
        const selectedFlag = featureFlags?.find(f => f.id === value);
        if (selectedFlag) {
            setValue('featureFlagId', selectedFlag.id, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    };

    const onSubmit = (data: FormInputs) => {
        props.handleSave({
            featureFlagId: data.featureFlagId,
            featureFlagSettings: featureFlagSettings
        });
    };

    const renderTitleRightComponent = (tooltip: string) => {
        if (tooltip) {
            return (
                <IconTooltip text={tooltip} place="top" />
            )
        }
        return null
    }

    return (
        <>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TopContainer>
                        <DropdownWrapper>
                            <Controller
                                name="featureFlagId"
                                control={control}
                                render={({ field }) => (
                                    <CustomDropdown
                                        {...field}
                                        handleOnChange={(optionValue, value) => {
                                            field.onChange(value);
                                            handleFeatureFlagChange(value as string);
                                        }}
                                        options={props.appContainer?.state?.currentApp.featureFlags ? props.appContainer?.state?.currentApp.featureFlags.map(f => ({ label: f.name, value: f.id })) : []}
                                        size="medium"
                                        label="Feature Flag"
                                        className="custom_drop_down"
                                    />
                                )}
                            />
                        </DropdownWrapper>
                        <ButtonWrapper>
                            <SaveButton
                                type="submit"
                                disabled={props.isLoading}
                                themes={["primary"]}
                                text="Save"
                                loading={props.isLoading}
                            />
                        </ButtonWrapper>
                    </TopContainer>
                    <CustomScrollbars
                        autoHide
                        autoHeight
                        autoHeightMax="calc(100vh - 390px)"
                    >
                        <SettingOptionsWrapper>
                            {featureFlagSettings?.sort((a, b) => a.renderOrder - b.renderOrder)?.map((flagSetting, index) => {
                                var defaultValue = "";
                                if (flagSetting.id === FoodOrderingGenAIModelSettingId) {
                                    defaultValue = GenAIModels[0].value;
                                }

                                if (flagSetting.id === FoodOrderingGenAIModelSettingId) {
                                    return (
                                        <CustomDropdown
                                            key={flagSetting.id}
                                            handleOnChange={(optionValue, value) => {
                                                const newSettings = [...featureFlagSettings];
                                                newSettings[index] = {
                                                    ...newSettings[index],
                                                    value: value as string
                                                };
                                                setFeatureFlagSettings(newSettings);
                                            }}
                                            fieldName="value"
                                            label={flagSetting.name}
                                            options={GenAIModels}
                                            size="medium"
                                            value={flagSetting.value?.length > 0 ? flagSetting.value : defaultValue}
                                            tooltip={flagSetting.description}
                                        />
                                    )
                                }
                                if (flagSetting.type === "checkbox") {
                                    return (
                                        <CheckboxField
                                            key={`KEY ${index}`}
                                            name={flagSetting.name}
                                            label={flagSetting.name}
                                            labelStyle={checkboxLabelStyle}
                                            disabled={props.isLoading}
                                            checked={flagSetting.value == "true"}
                                            tooltip={flagSetting.description}
                                            tooltipPosition={"right"}
                                            tooltipStyle={tooltipStyle}
                                            onChange={(e) => {
                                                console.log(e)
                                                const newSettings = [...featureFlagSettings];
                                                newSettings[index] = {
                                                    ...newSettings[index],
                                                    value: flagSetting.value == "true" ? "false" : "true"
                                                };
                                                setFeatureFlagSettings(newSettings);
                                            }}
                                            className={checkboxField}
                                        />
                                    )
                                }
                                return (
                                    <CollapsingSection title={flagSetting.name} id="input" bottom titleRightRowComponent={renderTitleRightComponent(flagSetting.description)}>
                                        <TextAreaField
                                            key={flagSetting.id}
                                            id={flagSetting.id}
                                            value={flagSetting.value?.length > 0 ? flagSetting.value : defaultValue}
                                            placeholder={`${flagSetting.name}`}
                                            className="field_value"
                                            onChange={(e) => {
                                                const newSettings = [...featureFlagSettings];
                                                newSettings[index] = {
                                                    ...newSettings[index],
                                                    value: e.target.value
                                                };
                                                setFeatureFlagSettings(newSettings);
                                            }}
                                        />
                                    </CollapsingSection>
                                );
                            })}
                            {(!featureFlagSettings || featureFlagSettings?.length == 0)
                                ?
                                <EmptyStateMessage>No settings are available for this feature flag</EmptyStateMessage>
                                :
                                <></>
                            }
                        </SettingOptionsWrapper>
                    </CustomScrollbars>
                </form>
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 24px;
    margin: 20px 40px;
    height: calc(100vh - 250px);
    background: ${color_defaults_white};
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-in-out;

    .field_value {
        width: 100%;
        margin-top: 14px;
        label {
            margin-left: 6px;
        }
        textarea {
            overflow-y: auto;
        }
    }

    .custom_drop_down {
        width: 300px;
        margin: 0;
    }
`;

const CollapsingSection = styled(ContentCollapsingSection)`
    margin-top: 32px;
`;

const checkboxLabelStyle = css`
    font-size: 16px;
    margin-bottom: 0px;
`;

const checkboxField = css`
    margin-top: 32px;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    position: sticky;
    top: 24px;
    background: ${color_defaults_white};
    z-index: 1;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
`;

const DropdownWrapper = styled.div`
    flex: 1;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin-left: 16px;
`;

const CustomDropdown = styled(StyledDropdown)``;

const SettingOptionsWrapper = styled.div`
    padding: 20px;
    margin-top: 8px;
`;

const SaveButton = styled(Button)`
    background-color: ${color_colors_ocean};
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
`;

const ErrorMessageWrapper = styled.div`
    color: ${dark_red};
    font-size: 14px;
    margin-top: 4px;
    margin-left: 6px;
`;

const EmptyStateMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: ${color_defaults_white};
    border-radius: 8px;
    border: 1px dashed #e0e0e0;
    color: #666;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin: 16px 0;
`;

const tooltipStyle = css`
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 8px;
  border: solid 1px ${text_blue};
  span {
    color: ${text_blue};
  }
`;

const tooltipContentStyle = css`
  .rc-tooltip-inner {
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    z-index: 120;
    color: ${color_shades_darker};
    background-color: white;
    border: 1px solid ${color_shades_dark};
    padding: 8px;
    min-height: 0;
    box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
  }
`;

export default FeatureSettings;
import { withFormik } from "formik";
import * as yup from 'yup';
import ApplicationInformationForm, { ApplicationInformationFormData, ApplicationInformationFormProps } from '../../formScenes/ApplicationInformationForm';
import UpdateApplicationRequest from "../../../models/applications/api/UpdateApplicationRequest";
import UpdateApplicationInformationByLanguageRequest from "../../../models/applications/api/UpdateApplicationInformationByLanguageRequest";
import UpdateApplicationEnvironmentRequest from "../../../models/applications/api/environments/UpdateApplicationEnvironmentRequest";

const UpdateApplicationForm = withFormik<ApplicationInformationFormProps, ApplicationInformationFormData>({
    mapPropsToValues: props => {

        const firstAppInfo = props.app?.applicationInformationItems?.find(a => a.languageId === props.languageIds[0]);
        if (!props.languageIds || props.languageIds.length < 1 || !firstAppInfo) {
            const formValuesFromProps: ApplicationInformationFormData = {
                name: props.app.name ? props.app.name : '',
                invocationPhrase: props.app.invocationPhrase ? props.app.invocationPhrase : '',
                shortDescription: props.app.shortDescription ? props.app.shortDescription : '',
                description: props.app.description ? props.app.description : '',
                keywords: props.app.keywords ? props.app.keywords : '',
                imageItemId: props.app.imageItemId,
                imageFile: null,
                selectedFlagIds: props.app.featureFlags ? props.app.featureFlags?.map(f => f.id) : [],
                featureFlags: props.app.featureFlags ? props.app.featureFlags : [],
                posLocationId: props.app.posLocationId ? props.app.posLocationId : '',
                posName: props.app.posName ? props.app.posName : '',
                manageMenuApplicationId: props.app.manageMenuApplicationId ? props.app.manageMenuApplicationId : '',
                outboundCallIntegrationName: props.app.outboundCallIntegrationName ? props.app.outboundCallIntegrationName : '',
                outboundCallLocationId: props.app.outboundCallLocationId ? props.app.outboundCallLocationId : '',
            };
            return formValuesFromProps;
        }
        else {
            const formValuesFromFirstApp: ApplicationInformationFormData = {
                name: firstAppInfo.name ? firstAppInfo.name : '',
                invocationPhrase: firstAppInfo.invocationPhrase ? firstAppInfo.invocationPhrase : '',
                shortDescription: firstAppInfo.shortDescription ? firstAppInfo.shortDescription : '',
                description: firstAppInfo.description ? firstAppInfo.description : '',
                keywords: firstAppInfo.keywords ? firstAppInfo.keywords : '',
                imageItemId: props.app.imageItemId,
                imageFile: null,
                selectedFlagIds: firstAppInfo.featureFlags ? firstAppInfo.featureFlags?.map(f => f.id) : [],
                featureFlags: firstAppInfo.featureFlags ? firstAppInfo.featureFlags : [],
                posLocationId: props.app.posLocationId ? props.app.posLocationId : '',
                posName: props.app.posName ? props.app.posName : '',
                manageMenuApplicationId: props.app.manageMenuApplicationId ? props.app.manageMenuApplicationId : '',
                outboundCallIntegrationName: props.app.outboundCallIntegrationName ? props.app.outboundCallIntegrationName : '',
                outboundCallLocationId: props.app.outboundCallLocationId ? props.app.outboundCallLocationId : '',
            };
            return formValuesFromFirstApp;
        }
    },
    validationSchema: yup.object().shape({
        name: yup.string().max(50).required("You must enter a name for your application"),
        shortDescription: yup.string().max(160),
        description: yup.string().max(4000),
        invocationPhrase: yup.string().required("You must enter an invocation name for your application").matches(/^([^0-9\!\&\*\(\)\{\}\%\$\#\@\!\?\;\:\-\_\+\=\/\<\>\~]*)$/, "Invocation name cannot contain digits or special characters"),
        outboundCallLocationId: yup.string().nullable(), // Allow empty string
    }),
    handleSubmit: async (values, { props, setStatus }) => {
        var featureFlags = props.appContainer.state.featureFlags;
        const newFlags = featureFlags.filter(f => values.selectedFlagIds.includes(f.id));
        const updateApplicationRequest: UpdateApplicationRequest = {
            imageItemId: values.imageItemId,
            name: props.app.name,
            invocationPhrase: props.app.invocationPhrase,
            shortDescription: props.app.shortDescription,
            description: props.app.description,
            keywords: props.app.keywords,
            applicationInformationItems: props.app.applicationInformationItems ? props.app.applicationInformationItems : [],
            featureFlags: newFlags,
            manageMenuApplicationId: values.manageMenuApplicationId,
            posName: values.posName,
            posLocationId: values.posLocationId,
            OutboundCallIntegrationName: values.outboundCallIntegrationName,
            OutboundCallLocationId: values.outboundCallLocationId,
        };

        const updateEnvironmentRequest: UpdateApplicationEnvironmentRequest = {
            imageItemId: values.imageItemId,
            name: props.environment?.name,
            applicationName: props.app.name,
            invocationPhrase: props.app.invocationPhrase,
            shortDescription: props.app.shortDescription,
            description: props.app.description,
            keywords: props.app.keywords,
            applicationInformationItems: props.app.applicationInformationItems ? props.app.applicationInformationItems : [],
            featureFlags: newFlags,
            manageMenuApplicationId: values.manageMenuApplicationId,
            posName: values.posName,
            posLocationId: values.posLocationId,
        };

        if (!props.languageIds || props.languageIds.length < 1) {
            // Update No Language values

            updateApplicationRequest.name = values.name
            updateApplicationRequest.invocationPhrase = values.invocationPhrase
            updateApplicationRequest.shortDescription = values.shortDescription
            updateApplicationRequest.description = values.description
            updateApplicationRequest.keywords = values.keywords
            updateApplicationRequest.featureFlags = newFlags
            updateEnvironmentRequest.applicationName = values.name
            updateEnvironmentRequest.invocationPhrase = values.invocationPhrase
            updateEnvironmentRequest.shortDescription = values.shortDescription
            updateEnvironmentRequest.description = values.description
            updateEnvironmentRequest.keywords = values.keywords

        } else {
            // for each language we are updating, add it if new, or update the existing

            props.languageIds.forEach(languageId => {

                if (updateApplicationRequest.applicationInformationItems.some(a => a.languageId === languageId)) {
                    const existingItem: UpdateApplicationInformationByLanguageRequest =
                        updateApplicationRequest.applicationInformationItems.find(a => a.languageId === languageId);

                    existingItem.name = values.name
                    existingItem.invocationPhrase = values.invocationPhrase
                    existingItem.shortDescription = values.shortDescription
                    existingItem.description = values.description
                    existingItem.keywords = values.keywords
                    existingItem.environmentId = props.environment.id
                    existingItem.featureFlags = newFlags
                    existingItem.manageMenuApplicationId = values.manageMenuApplicationId
                    existingItem.posName = values.posName
                    existingItem.posLocationId = values.posLocationId
                }
                else {
                    const newItem: UpdateApplicationInformationByLanguageRequest = {
                        languageId: languageId,
                        name: values.name,
                        invocationPhrase: values.invocationPhrase,
                        shortDescription: values.shortDescription,
                        description: values.description,
                        keywords: values.keywords,
                        environmentId: props.environment.id,
                        featureFlags: newFlags,
                        manageMenuApplicationId: values.manageMenuApplicationId,
                        posName: values.posName,
                        posLocationId: values.posLocationId
                    };
                    updateApplicationRequest.applicationInformationItems.push(newItem);
                }

                if (updateEnvironmentRequest.applicationInformationItems.some(a => a.languageId === languageId)) {
                    const existingItem: UpdateApplicationInformationByLanguageRequest =
                        updateEnvironmentRequest.applicationInformationItems.find(a => a.languageId === languageId);

                    existingItem.name = values.name
                    existingItem.invocationPhrase = values.invocationPhrase
                    existingItem.shortDescription = values.shortDescription
                    existingItem.description = values.description
                    existingItem.keywords = values.keywords
                    existingItem.environmentId = props.environment?.id
                    existingItem.featureFlags = newFlags
                    existingItem.manageMenuApplicationId = values.manageMenuApplicationId
                    existingItem.posName = values.posName
                    existingItem.posLocationId = values.posLocationId
                }
                else {
                    const newItem: UpdateApplicationInformationByLanguageRequest = {
                        languageId: languageId,
                        name: values.name,
                        invocationPhrase: values.invocationPhrase,
                        shortDescription: values.shortDescription,
                        description: values.description,
                        keywords: values.keywords,
                        environmentId: props.environment?.id,
                        featureFlags: newFlags,
                        manageMenuApplicationId: values.manageMenuApplicationId,
                        posName: values.posName,
                        posLocationId: values.posLocationId
                    };
                    updateEnvironmentRequest.applicationInformationItems.push(newItem);
                }
            });
        };
        // Update Application Settings
        await props.appContainer.updateApplication(props.app.id, updateApplicationRequest).then(async result => {
            if (values.imageFile) {
                await props.appContainer.updateApplicationImage(props.app.id, values.imageFile).then(imgResult => {
                    // Get application's new imageItemId and assign it to Live environment
                    updateEnvironmentRequest.imageItemId = imgResult?.data?.id;
                });
            }
            let message = "";
            if (result.resultType === "Ok") {
                message = "Application updated successfully";
            } else {
                message = "There was an error updating the application";
            }
            setTimeout(() => {
                setStatus({ message });
            }, 5000);
        });

        // Update Live Environment Settings
        if (props.environmentOption === "live" && props.environment) {
            props.environmentsContainer.updateEnvironmentSettings(props.app.id, props.environment.id, updateEnvironmentRequest);
        }

    }
})(ApplicationInformationForm);

export default UpdateApplicationForm;

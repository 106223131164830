import React, { useEffect, useState } from 'react';
import { FormikProps, withFormik } from "formik";
import * as yup from 'yup';
import WebhookModel from '../../../models/webhooks/api/WebhookModel';
import { css } from '@emotion/css';
import { color_shades_dark, color_shades_darkest } from '../../../constants/colors';
import WebhookContainer from '../../../state/containers/WebhookContainer';
import WebhookInstanceModel from '../../../models/webhooks/WebhookInstanceModel';
import FormWebhookInstance from '../../../models/webhooks/FormWebhookInstance';
import FormParameterValue from '../../../models/webhooks/FormParameterValue';
import WebhookInstanceRequest from '../../../models/webhooks/api/WebhookInstanceRequest';
import { WebhookInstanceScope } from '..';
import _ from 'lodash';
import BulkWebhookInstancesUpdateRequest from '../../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import { WebhookChainingType } from '../../../models/webhooks/api/WebhookChainingType';
import ModalLayout from '../../../components/structure/ModalLayout';
import FullScreenLayout from '../../fullScreenLayout';
import PageContainer from '../../../components/structure/PageContainer';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import ModalTitle from '../../../components/general/ModalTitle';
import Button from '../../../components/general/Button';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import SelectField from '../../../components/forms/SelectField';
import AttachedWebhookCard from './AttachedWebhookCard';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import TextOverflowMenu from '../../../components/general/overflowMenu/TextOverflowMenu';
import FooterBar from '../../../components/structure/FooterBar';
import Loader from '../../../components/general/Loader';
import useWindowSize from '../../../hooks/UseWindowSize';
const closeIcon = require('../../../content/images/close_icon.svg');

interface WebhookInstanceFormProps {
    isLoading: boolean,
    webhookInstanceScope: WebhookInstanceScope,
    existingWebhookChainingType?: WebhookChainingType,
    existingWebhookInstances?: WebhookInstanceModel[],
    stagedUpdate?: BulkWebhookInstancesUpdateRequest,
    webhookContainer: WebhookContainer,
    applicationId: string,
    organizationId: string,
    onFinish: (updateRequest: BulkWebhookInstancesUpdateRequest) => void,
    onClose: () => void
}

const WEBHOOK_CHAINING_ENABLED = "Enabled";
const WEBHOOK_CHAINING_DISABLED = "Disabled"
const WebhookInstanceFormComponent: React.FC<WebhookInstanceFormProps & FormikProps<WebhookInstanceFormData>> = (props) => {
    const [selectedWebhook, setSelectedWebhook] = useState({} as WebhookModel);
    const [availableWebhooks, setAvailableWebhooks] = useState([] as WebhookModel[]);
    const [eventWebhooks, setEventWebhooks] = useState([] as FormWebhookInstance[]);
    const [preResponseWebhoooks, setPreResponseWebhooks] = useState([] as FormWebhookInstance[]);
    const [loaded, setLoaded] = useState(false);
    const windowSize = useWindowSize();
    useEffect(() => {
        const asyncLoad = async () => {
            var applicationWebhooksResult = await props.webhookContainer.getWebhooksForApplication(props.applicationId);
            const applicationWebhooks = applicationWebhooksResult.data.filter(w => w.webhookType.webhookScopes.some(as => as.name == props.webhookInstanceScope));
            if (applicationWebhooksResult.resultType != "Ok")
                return;
            await setAvailableWebhooks(applicationWebhooks);
            await setLoaded(true);
        }
        asyncLoad();
    }, [props.applicationId]);

    useEffect(() => {
        let newEventWebhooks: FormWebhookInstance[] = [];
        let newPreResponseWebhooks: FormWebhookInstance[] = [];
        for (let i = 0; i < props.values.eventWebhookInstances.length; i++) {
            // pre response webhooks are coming through as event webhooks into this component, lets separate them out. 
            const whToCheck = props.values.eventWebhookInstances[i];
            whToCheck.instanceName = `eventWebhookInstances.${i}`;
            whToCheck.eventWebhookIndex = i;
            if (whToCheck.webhook.webhookType.typeName === "PreResponse") {
                if (whToCheck.priority === null || whToCheck.priority === undefined || whToCheck.priority < 0) {
                    whToCheck.priority = newPreResponseWebhooks.length + 1;
                    props.setFieldValue(`${whToCheck.instanceName}.priority`, whToCheck.priority);
                }
                newPreResponseWebhooks.push(whToCheck);
            } else {
                newEventWebhooks.push(whToCheck);
            }
        }
        setEventWebhooks(newEventWebhooks);
        // order by pre response priority
        newPreResponseWebhooks = _.orderBy(newPreResponseWebhooks, af => af.priority, 'asc');
        setPreResponseWebhooks(newPreResponseWebhooks);
    }, [props.values.eventWebhookInstances])
    useEffect(() => {
        if (props.stagedUpdate != null && loaded) {
            var eventWebhooks: FormWebhookInstance[] = [];
            var responseRespolverWebhooks: FormWebhookInstance[] = [];
            var sessionStartWebhooks: FormWebhookInstance[] = [];
            props.stagedUpdate.webhookInstances.forEach(wi => {
                const newParameterValues: FormParameterValue[] = [];
                const webhook = availableWebhooks.find(w => w.id == wi.webhookId);
                const filledParameters = { ...wi.parameters };
                webhook.parameters.forEach(p => {
                    if (p.isRequired) {
                        const existingValue = filledParameters[p.name];
                        newParameterValues.push({
                            key: p.name,
                            value: existingValue ?? p.defaultValue ?? "",
                            isRequired: true
                        });
                        delete filledParameters[p.name];
                    }
                });
                for (let key in filledParameters) {
                    newParameterValues.push({
                        key: key,
                        value: filledParameters[key],
                        isRequired: false
                    })
                }

                if (webhook.webhookType.typeName == "ResponseResolver") {
                    responseRespolverWebhooks.push({
                        webhook: {
                            ...webhook
                        },
                        priority: wi.priority,
                        formParameterValues: newParameterValues
                    });
                }
                else if (webhook.webhookType.typeName == "SessionStart") {
                    sessionStartWebhooks.push({
                        webhook: {
                            ...webhook
                        },
                        formParameterValues: newParameterValues
                    });
                }
                else {
                    eventWebhooks.push({
                        webhook: {
                            ...webhook
                        },
                        formParameterValues: newParameterValues
                    });
                }
            });
            props.setFieldValue("eventWebhookInstances", eventWebhooks);
            props.setFieldValue("sessionStartWebhookInstances", sessionStartWebhooks);
            props.setFieldValue("responseResolverWebhookInstances", _.orderBy(responseRespolverWebhooks, af => af.priority, 'asc'));
            props.setFieldValue("webhookChainingType", props.stagedUpdate.webhookChainingType == "NoChaining" ? WEBHOOK_CHAINING_DISABLED : WEBHOOK_CHAINING_ENABLED);
        }
    }, [props.stagedUpdate, loaded]);
    useEffect(() => {
        if (props.existingWebhookInstances
            && props.existingWebhookInstances.length > 0
            && props.values.sessionStartWebhookInstances.length == 0
            && props.values.responseResolverWebhookInstances.length == 0
            && props.values.eventWebhookInstances.length == 0
        ) {
            var eventWebhooks: FormWebhookInstance[] = [];
            var sessionStartWebhooks: FormWebhookInstance[] = [];
            var responseRespolverWebhooks: FormWebhookInstance[] = [];
            props.existingWebhookInstances.forEach(wi => {
                const newParameterValues: FormParameterValue[] = [];
                wi.webhook.parameters.forEach(p => {
                    if (p.isRequired) {
                        const existingValue = wi.parameterValues.find(pv => pv.webhookParameter.name == p.name)?.value;
                        newParameterValues.push({
                            key: p.name,
                            value: existingValue ?? p.defaultValue ?? "",
                            isRequired: true
                        });
                    }
                });
                wi.parameterValues.forEach(pv => {
                    if (newParameterValues.some(npv => npv.key == pv.webhookParameter.name))
                        return;
                    newParameterValues.push({
                        key: pv.webhookParameter.name ?? pv.userDefinedKey,
                        value: pv.value,
                        isRequired: false
                    })
                });

                if (wi.webhook.webhookType.typeName == "ResponseResolver") {
                    responseRespolverWebhooks.push({
                        ...wi,
                        priority: wi.priority ?? (responseRespolverWebhooks.length + 1),
                        formParameterValues: newParameterValues
                    });
                }
                else if (wi.webhook.webhookType.typeName == "SessionStart") {
                    sessionStartWebhooks.push({
                        ...wi,
                        formParameterValues: newParameterValues
                    });
                }
                else {
                    eventWebhooks.push({
                        ...wi,
                        formParameterValues: newParameterValues
                    });
                }
            });
            props.setFieldValue("sessionStartWebhookInstances", sessionStartWebhooks);
            props.setFieldValue("eventWebhookInstances", eventWebhooks);
            props.setFieldValue("webhookChainingType", props.existingWebhookChainingType == "NoChaining" ? WEBHOOK_CHAINING_DISABLED : WEBHOOK_CHAINING_ENABLED);
            props.setFieldValue("responseResolverWebhookInstances", _.orderBy(responseRespolverWebhooks, af => af.priority, 'asc'));
        }
    }, [props.existingWebhookInstances,
    props.values.sessionStartWebhookInstances,
    props.values.eventWebhookInstances,
    props.values.responseResolverWebhookInstances])

    const handleWebhookChange = (value) => {
        const id = value === null ? null : value.value;
        const webhook = availableWebhooks?.find(w => w.id == id);
        setSelectedWebhook(webhook);
    }
    const removeResponseResolver = (idx: number) => {
        const webhookInstances = [...props.values.responseResolverWebhookInstances];
        webhookInstances.splice(idx, 1);
        props.setFieldValue("responseResolverWebhookInstances", webhookInstances);
    }
    const removeEventInstance = (idx: number) => {
        const webhookInstances = [...props.values.eventWebhookInstances];
        webhookInstances.splice(idx, 1);
        props.setFieldValue("eventWebhookInstances", webhookInstances);
    }
    const removeSessionStartInstance = (idx: number) => {
        const webhookInstances = [...props.values.eventWebhookInstances];
        webhookInstances.splice(idx, 1);
        props.setFieldValue("sessionStartWebhookInstances", webhookInstances);
    }
    const handleAddAttachWebhook = () => {
        var webhookInstances = selectedWebhook?.webhookType?.typeName == "ResponseResolver" ? props.values.responseResolverWebhookInstances : props.values.eventWebhookInstances;
        const newParameterValues: FormParameterValue[] = [];
        selectedWebhook?.parameters?.forEach(p => {
            if (p.isRequired) {
                newParameterValues.push({
                    key: p.name,
                    value: p.defaultValue ?? "",
                    isRequired: true
                });
            }
        });
        const newPriority = (selectedWebhook?.webhookType?.typeName == "ResponseResolver" && props.webhookInstanceScope == "Content") ? props.values.responseResolverWebhookInstances.length + 1 : null;
        const newWebhookInstance: FormWebhookInstance = {
            webhook: selectedWebhook,
            formParameterValues: newParameterValues,
            priority: newPriority
        };
        webhookInstances = [...webhookInstances, newWebhookInstance];
        if (newWebhookInstance.webhook.webhookType.typeName == "ResponseResolver") {
            props.setFieldValue("responseResolverWebhookInstances", webhookInstances);
        }
        else if (newWebhookInstance.webhook.webhookType.typeName == "SessionStart") {
            props.setFieldValue("sessionStartWebhookInstances", webhookInstances);
        }
        else {
            props.setFieldValue("eventWebhookInstances", webhookInstances);
        }
    }
    const handleResponseResolverDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let webhookInstances = [...props.values.responseResolverWebhookInstances];
        const reorder = (list: any[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        webhookInstances = reorder(webhookInstances, result.source.index, result.destination.index);
        webhookInstances.forEach((wi, idx) => {
            wi.priority = idx + 1;
        });
        props.setFieldValue("responseResolverWebhookInstances", webhookInstances);

    }
    const handlePreResponseDragEnd = (result: DropResult, provider: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let webhookInstances = [...preResponseWebhoooks];
        const reorder = (list: any[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        webhookInstances = reorder(webhookInstances, result.source.index, result.destination.index);
        webhookInstances.forEach((wi, idx) => {
            wi.priority = idx + 1;
            props.setFieldValue(`${wi.instanceName}.priority`, wi.priority);
        });
        setPreResponseWebhooks(webhookInstances);

    }
    const isLoading = props.isLoading || availableWebhooks == null;
    return (
        <ModalLayout className={modalStyle} isVisible={true} onClose={props.onClose}>
            <form onSubmit={props.handleSubmit}>
                <FullScreenLayout className={containerStyle}>
                    <PageContainer fill>
                        <HorizontalContainer className="header-wrapper">
                            <ModalTitle>Webhook Instances</ModalTitle>
                            <Button type="button" className="close-icon" themes={['end-tight', 'icon']} icon={closeIcon} onClick={props.onClose} />
                        </HorizontalContainer>
                        {!loaded && <Loader />}
                        {loaded && <CustomScrollbars autoHide autoHeight autoHeightMin={(windowSize.windowSize.innerHeight * .9) - 188} autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 188}>
                            <div className={"form-container"}>
                                <div className={"sub-heading"}>Attach a Webhook</div>
                                <HorizontalContainer className="attach-container">
                                    <SelectField
                                        className={selectorStyle}
                                        options={availableWebhooks?.map(o => { return { value: o.id, label: o.title } })}
                                        value={selectedWebhook?.id}
                                        onBlur={() => null}
                                        onChange={handleWebhookChange.bind(this)}
                                        disabled={isLoading}
                                        label="Select an existing webhook"
                                        placeholder="- Select a webhook -"
                                        clearValueText="- Select a webhook -"
                                        name="available-webhooks"
                                        clearable={true} />
                                    <Button onClick={handleAddAttachWebhook.bind(this)} type="button" className="attach-button" loading={isLoading} disabled={isLoading} themes={["primary", "wide"]} text={"Attach"} />
                                </HorizontalContainer>

                                {
                                    (props.values.responseResolverWebhookInstances.length > 0 || props.values.eventWebhookInstances.length > 0) &&
                                    <div className={"sub-heading"}>Existing Webhooks</div>
                                }
                                {
                                    (props.values.sessionStartWebhookInstances.length > 0) &&
                                    <>
                                        <div className={"sub-heading-upper"}>Session Start Webhook</div>
                                        {
                                            props.values.sessionStartWebhookInstances.map((wi, idx) =>
                                                <AttachedWebhookCard
                                                    webhookInstance={wi}
                                                    index={idx}
                                                    handleBlur={props.handleBlur}
                                                    handleChange={props.handleChange}
                                                    setFieldValue={props.setFieldValue}
                                                    onRemove={() => removeSessionStartInstance(idx)}
                                                    instanceName={`sessionStartWebhookInstances.${idx}`} />
                                            )
                                        }
                                    </>
                                }
                                {
                                    preResponseWebhoooks.length > 0 &&
                                    <>
                                        <div className={"sub-heading-upper response"}>
                                            <div>Pre Response Webhooks</div>
                                        </div>
                                        {preResponseWebhoooks.length > 1 ?
                                            <DragDropContext onDragEnd={handlePreResponseDragEnd.bind(this)}>
                                                <Droppable droppableId={"prWebhookInstance"} type="P_R_WEBHOOK_INSTANCES">
                                                    {
                                                        (provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}>
                                                                {preResponseWebhoooks.map((wi, idx) => {
                                                                    return (<Draggable draggableId={`pre-response-webhook-instance${idx}`} index={idx} key={`pre-response-webhook-instance${idx}`}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}>
                                                                                <AttachedWebhookCard
                                                                                    className={snapshot.isDragging ? 'dragging' : ''}
                                                                                    webhookInstance={wi}
                                                                                    index={idx}
                                                                                    showPriority={true}
                                                                                    dragHandleProps={provided.dragHandleProps}
                                                                                    handleBlur={props.handleBlur}
                                                                                    handleChange={props.handleChange}
                                                                                    setFieldValue={props.setFieldValue}
                                                                                    onRemove={() => removeEventInstance(wi.eventWebhookIndex)}
                                                                                    instanceName={wi.instanceName} />
                                                                            </div>
                                                                        )}
                                                                    </Draggable>)
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )
                                                    }
                                                </Droppable>
                                            </DragDropContext>
                                            :

                                            preResponseWebhoooks.map((wi, idx) =>
                                                <AttachedWebhookCard
                                                    webhookInstance={wi}
                                                    index={idx}
                                                    handleBlur={props.handleBlur}
                                                    handleChange={props.handleChange}
                                                    setFieldValue={props.setFieldValue}
                                                    onRemove={() => removeEventInstance(wi.eventWebhookIndex)}
                                                    instanceName={wi.instanceName} />
                                            )

                                        }

                                    </>
                                }
                                {
                                    props.values.responseResolverWebhookInstances.length > 0 &&
                                    <>
                                        <div className={"sub-heading-upper response"}>
                                            <div>Response Resolver Webhooks</div>
                                            {props.webhookInstanceScope == "Content" &&
                                                <TextOverflowMenu
                                                    clickableText={props.values.webhookChainingType}
                                                    className="webhook-chaining-select"
                                                    label="Webhook Chaining: "
                                                    onOptionSelected={(option) => props.setFieldValue("webhookChainingType", option)}
                                                    options={[{ label: WEBHOOK_CHAINING_ENABLED }, { label: WEBHOOK_CHAINING_DISABLED }]} />
                                            }
                                        </div>
                                        {(props.webhookInstanceScope == "Content" && props.values.webhookChainingType == WEBHOOK_CHAINING_ENABLED) ?
                                            <DragDropContext onDragEnd={handleResponseResolverDragEnd.bind(this)}>
                                                <Droppable droppableId={"webhookInstances"} type="WEBHOOK_INSTANCES">
                                                    {
                                                        (provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}>
                                                                {props.values.responseResolverWebhookInstances.map((wi, idx) => {
                                                                    return (<Draggable draggableId={`webhook-instance${idx}`} index={idx} key={`webhook-instance${idx}`}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}>
                                                                                <AttachedWebhookCard
                                                                                    className={snapshot.isDragging ? 'dragging' : ''}
                                                                                    webhookInstance={wi}
                                                                                    index={idx}
                                                                                    showPriority={true}
                                                                                    dragHandleProps={provided.dragHandleProps}
                                                                                    handleBlur={props.handleBlur}
                                                                                    handleChange={props.handleChange}
                                                                                    setFieldValue={props.setFieldValue}
                                                                                    onRemove={() => removeResponseResolver(idx)}
                                                                                    instanceName={`responseResolverWebhookInstances.${idx}`} />
                                                                            </div>
                                                                        )}
                                                                    </Draggable>)
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )
                                                    }
                                                </Droppable>
                                            </DragDropContext>
                                            :

                                            props.values.responseResolverWebhookInstances.map((wi, idx) =>
                                                <AttachedWebhookCard
                                                    webhookInstance={wi}
                                                    index={idx}
                                                    handleBlur={props.handleBlur}
                                                    handleChange={props.handleChange}
                                                    setFieldValue={props.setFieldValue}
                                                    onRemove={() => removeResponseResolver(idx)}
                                                    instanceName={`responseResolverWebhookInstances.${idx}`} />
                                            )

                                        }

                                    </>
                                }
                                {
                                    (eventWebhooks.length > 0) &&
                                    <>
                                        <div className={"sub-heading-upper"}>Event Webhooks</div>
                                        {
                                            eventWebhooks.map((wi, idx) =>
                                                <AttachedWebhookCard
                                                    webhookInstance={wi}
                                                    index={idx}
                                                    handleBlur={props.handleBlur}
                                                    handleChange={props.handleChange}
                                                    setFieldValue={props.setFieldValue}
                                                    onRemove={() => removeEventInstance(wi.eventWebhookIndex)}
                                                    instanceName={wi.instanceName} />
                                            )
                                        }
                                    </>
                                }
                            </div>
                        </CustomScrollbars>}
                        <FooterBar>
                            <Button className="submit-button" loading={isLoading} disabled={isLoading || !loaded} themes={["primary", "wide"]} text={"Save"} type="submit" />
                            <Button className="cancel-button" disabled={isLoading || !loaded} themes={["white", "end"]} text="Cancel" type="button" onClick={props.onClose.bind(this)} />
                        </FooterBar>
                    </PageContainer>
                </FullScreenLayout>
            </form>
        </ModalLayout>
    );

}

export interface WebhookInstanceFormData {
    eventWebhookInstances: FormWebhookInstance[],
    sessionStartWebhookInstances: FormWebhookInstance[],
    responseResolverWebhookInstances: FormWebhookInstance[],
    webhookChainingType: string
}

const webhookInstanceObjectValidation = yup.object().shape({
    formParameterValues: yup.array().of(yup.object().shape({
        key: yup.string().required("You must specify a parameter key"),
        value: yup.string().when("isRequired", {
            is: true,
            then: yup.string().required("This parameter is required, please specify a value.")
        }),
        isRequired: yup.boolean()
    }))
});

const WebhookInstanceForm = withFormik<WebhookInstanceFormProps, WebhookInstanceFormData>({
    mapPropsToValues: props => {
        return {
            sessionStartWebhookInstances: [],
            eventWebhookInstances: [],
            responseResolverWebhookInstances: [],
            webhookChainingType: WEBHOOK_CHAINING_DISABLED
        };
    },
    validationSchema: yup.object().shape({
        sessionStartWebhookInstances: yup.array().of(
            webhookInstanceObjectValidation
        ),
        eventWebhookInstances: yup.array().of(
            webhookInstanceObjectValidation
        ),
        responseResolverWebhookInstances: yup.array().of(
            webhookInstanceObjectValidation
        ),
        webhookChainingType: yup.string()
    }),
    handleSubmit: (values, { props }) => {
        var allInstances = [...values.eventWebhookInstances, ...values.responseResolverWebhookInstances, ...values.sessionStartWebhookInstances];
        const instances: WebhookInstanceRequest[] = [];
        allInstances.forEach(e => {
            const parameters: { [key: string]: string } = {};
            e.formParameterValues.forEach(f => {
                let val = f.value;
                if (val == "" || val == null) {
                    const parameterValues = e.webhook.parameters?.filter(p => p.name == f.key) ?? []
                    if (parameterValues.length > 0) {
                        val = parameterValues[0].defaultValue ?? "";
                    }
                }
                parameters[f.key] = val
            });
            instances.push({
                webhookId: e.webhook.id,
                parameters: parameters,
                priority: e.priority
            });
        });
        props.onFinish({ webhookInstances: instances, webhookChainingType: values.webhookChainingType == WEBHOOK_CHAINING_ENABLED ? "InstanceLevelChaining" : "NoChaining" });
    }
})(WebhookInstanceFormComponent);

export default WebhookInstanceForm;

const modalStyle = css`
    width: 70vw;
    height: 90vh;
`;

const containerStyle = css`
    .header-wrapper {
        .close-icon {
            margin-right: 32px;
            margin-top: 28px;
        }
        h2 {
            margin-left: 32px;
        }
        border-bottom: solid 1px ${color_shades_dark};    
    }
    .form-container {
        margin: 32px;
    }    
    .submit-button {
        margin-left: 32px;
    }
    .cancel-button {
        margin-right: 32px;
    }
    .attach-button {
        margin-top: 32px;
        margin-right: 0px;
    }
    .sub-heading {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: ${color_shades_darkest};
        margin-bottom: 16px;
    }
    .sub-heading-upper {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${color_shades_darkest};
        margin-top: 32px;
        &.response {
            display: flex;
            .webhook-chaining-select {  
                margin-left: auto;
                margin-right: 8px;
                text-transform: none;
            }
        }
    }
    .attach-container {
        border-bottom: solid 1px ${color_shades_dark};
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
`;

const selectorStyle = css`
    margin-left: 0px;
    width: 100%;
    &.left {
        padding-right: 8px;
    }
    &.right {
        padding-left: 8px;
    }
    .Select-control {
        border-radius: 8px;
        height: 60px;
    }
    .Select-input {
        margin: 0px;
    }
    
`;
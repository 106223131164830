import { FormikErrors } from "formik";
import { useState } from "react"
import { createContainer } from "unstated-next"
import { InteractionModelFormData } from "../scenes/interactionModel/components/InteractionModelForm";

export type NluTab = "Intents" | "Entities" | "Menu" | "Feature Settings";
export type MenuEditorView = "Menu Items" | "Option Groups" | "Size Groups"

function useInteractionModelUiStateContainer() {
    const [nluTab, setNluTab] = useState("Entities" as NluTab);
    const [menuEditorView, setMenuEditorView] = useState("Menu Items" as MenuEditorView);
    const [triggerSaveChanges, setTriggerSaveChanges] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [errors, setErrors] = useState<FormikErrors<InteractionModelFormData>>({});

    return {
        nluTab,
        triggerSaveChanges,
        isFormDirty,
        setNluTab,
        setTriggerSaveChanges,
        setIsFormDirty,
        errors,
        setErrors,
        menuEditorView,
        setMenuEditorView
    };
}

const InteractionModelUiStateContainer = createContainer(useInteractionModelUiStateContainer);
export default InteractionModelUiStateContainer;

import React, { useEffect, useState } from "react";
import { color_shades_dark } from "../../../constants/colors";
import styled from "@emotion/styled";
import RoundedSelectionRow from "../../../components/general/RoundedSelectionRow";
import Button from "../../../components/general/Button";
import { css } from "@emotion/css";
import InteractionModelUiStateContainer from "../../../hooks/InteractionModelUiStateContainer";
import MediaTypeOptionModel from "../../../models/media/MediaTypeOptionModel";
import { FoodOrderingFeatureFlagId, GenAIFoodOrdering } from "../../../constants/featureFlagIds";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";

const alertIcon = require('../../../content/images/alert-circle.svg');

interface InteractionModelSettingsHeaderProps {
    applicationId: string,
    history: any
    appContainer: ApplicationContainer
}

const InteractionModelSettingsHeader = (props: InteractionModelSettingsHeaderProps) => {
    const uiStateContainer = InteractionModelUiStateContainer.useContainer();

    useEffect(() => {
        uiStateContainer.setTriggerSaveChanges(false);
    }, []);

    const showMenuTab = props.appContainer?.state?.currentApp?.featureFlags?.some((flag) => flag.id === FoodOrderingFeatureFlagId);

    const getOptions = (): MediaTypeOptionModel[] => {

        const option = [
            { name: "Entities" },
            { name: "Intents" }
        ];

        if (showMenuTab) {
            option.push({ name: "Menu" });
        }

        if (props.appContainer?.state?.currentApp?.featureFlags?.length > 0) {
            option.push({ name: "Feature Settings" });
        }

        return option;
    };

    if (!!(Object.keys(uiStateContainer?.errors?.nlpEntities ?? {})?.length)) {
        getOptions()[0].icon = alertIcon;
        getOptions()[0].selectedIcon = alertIcon;
    };

    if (!!(Object.keys(uiStateContainer?.errors?.interactionModel ?? {})?.length)) {
        getOptions()[1].icon = alertIcon;
        getOptions()[1].selectedIcon = alertIcon;
    };

    const selectedFilter = (tabs) => {
        var options = getOptions();
        const tabPath = window.location.pathname.split('/').pop();
        if (tabPath === 'featureSettings') {
            if (options.some(o => o.name == "Menu")) {
                return 3;
            }
            return 2;
        }
        switch (tabs) {
            case "Entities":
                return 0;
            case "Intents":
                return 1;
            case "Menu":
                return 2;
            case "Feature Settings":
                return 3;
            default:
                return 0;
        }
    };

    var options = getOptions();
    return (
        <InteractionModelAdditionalHeader>
            <span className="header-title">NLP Model</span>
            <ToggleContainer>
                <RoundedSelectionRow
                    key={"nlu-sections-selector"}
                    options={options}
                    className={roundedSelectStyle}
                    optionClassName={roundedOptionStyle}
                    optionTextClassName={optionTextStyle}
                    onClick={(num) => props.history.push(`/v/apps/${props.applicationId}/appSettings/nlp/${num === 1 ? "intents" :
                        num === 2 ? options.some(o => o.name == "Menu") ? "menu" : "featureSettings" :
                            num === 3 ? "featureSettings" :
                                "entities"
                        }`)}
                    selectedFilter={selectedFilter(uiStateContainer.nluTab)}
                />
            </ToggleContainer>
            {uiStateContainer.nluTab !== "Feature Settings" &&
                <SaveChangesButton
                    type="button"
                    themes={["primary-small"]}
                    text="Save Changes"
                    onClick={() => uiStateContainer.setTriggerSaveChanges(true)} />
            }

        </InteractionModelAdditionalHeader>
    )

}

export default InteractionModelSettingsHeader;

const AdditionalHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 100vw; // auto margin just refuses to work here
    padding-left: 32px;
    margin-left: 7px;
    white-space: nowrap;
    border-left: 1px solid ${color_shades_dark};
    height: 100%;
    padding-top: 0;
    .header-title {
        padding: 16px 16px 0 0;
    }

`;
const InteractionModelAdditionalHeader = styled(AdditionalHeader)`
    margin-right: 0px;
    margin-left: 7px;
    width: 100%;
`;

const ToggleContainer = styled.div`
    min-width: 349px;
    padding: 0 10px 10px 0;
    margin: 8px auto 0 0;
`;

const SaveChangesButton = styled(Button)`
    margin-top: 12px;
    margin-right: 32px;
`;

const roundedSelectStyle = css`
    height: 32px
`;

const optionTextStyle = css`
    margin-bottom: 4px;
`;

const roundedOptionStyle = css`
    height: 35px;
`;
import { css } from '@emotion/css';
import { FieldArray } from "formik";
import React from "react";
import { Draggable, DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import TextField from "../../../components/forms/TextField";
import HorizontalContainer from "../../../components/structure/HorizontalContainer";
import { color_colors_ocean, color_shades_dark, color_shades_darkest, color_shades_lighter, color_variants_ocean_light_2 } from "../../../constants/colors";
import FormWebhookInstance from "../../../models/webhooks/FormWebhookInstance";
const linkOutIcon = require('../../../content/images/link_out.svg');
const settingsBoxBlue = require('../../../content/images/webhooks/settings-box-blue.svg');
const settingsBoxDark = require('../../../content/images/webhooks/settings-box-dark.svg');
const draggableInstance = require('../../../content/images/webhooks/draggable-instance.svg');
const deleteIcon = require('../../../content/images/content-explorer/delete.svg');
const descriptionIcon = require("../../../content/images/paragraph-normal.svg");
const typeIcon = require("../../../content/images/hierarchy.svg");
const linkIcon = require("../../../content/images/hyperlink-circle.svg");
const keyIcon = require("../../../content/images/login-keys.svg");
const removeParameterIcon = require('../../../content/images/webhooks/remove-parameter.svg');
const requiredStarIcon = require('../../../content/images/webhooks/parameter-required-star.svg');



interface AttachedWebhookCardProps {
    webhookInstance: FormWebhookInstance
    index: number
    instanceName: string,
    handleChange,
    handleBlur,
    setFieldValue,
    onRemove: () => void
    dragHandleProps?: DraggableProvidedDragHandleProps
    showPriority?: boolean
    className?: string
}

interface AttachedWebhookCardState {
    configExpanded: boolean
}

class AttachedWebhookCard extends React.Component<AttachedWebhookCardProps, AttachedWebhookCardState> {
    constructor(props) {
        super(props);
        this.state = {
            configExpanded: false,
        }
    }
    toggleExpandConfig() {
        this.setState({
            ...this.state,
            configExpanded: !this.state.configExpanded
        });
    }
    renderWebhookConfigField(label: string, icon: string, value: string, first: boolean) {
        return (
            <div key={label} className={`${webhookConfigFieldStyle} ${first ? '' : 'extra-margin-top'}`}>
                <div className="label">{label}</div>
                <HorizontalContainer className="line-two">
                    {icon && <img src={icon} />}
                    <div className="value">{value}</div>
                </HorizontalContainer>
            </div>
        );
    }
    render() {
        const webhookInstance = this.props.webhookInstance;
        const suggestions = webhookInstance.webhook.parameters?.filter(p => !p.isRequired && !webhookInstance.formParameterValues.some(fpv => fpv.key == p.name))?.map(p => p.name) ?? [];
        return (
            <div key={this.props.index} className={`${attachedWebhookStyle} ${this.props.className ?? ''}`}>
                <HorizontalContainer className="top">
                    {
                        this.props.showPriority &&
                        <div {...this.props.dragHandleProps} className="webhook-priority">
                            <img src={draggableInstance} />
                            <span>{this.props.webhookInstance.priority}</span>
                        </div>
                    }
                    <span className="title">{webhookInstance.webhook.title}</span>
                    <a target="_blank" className="link-out-icon" href={`/v/apps/${webhookInstance.webhook.applicationId}/webhooks/${webhookInstance.webhook.webhookFolderId ?? ""}?webhookId=${webhookInstance.webhook.id}`}>
                        <img src={linkOutIcon} />
                    </a>
                    <div onClick={this.toggleExpandConfig.bind(this)} className={`show-config ${this.state.configExpanded ? 'expanded' : ''}`}>
                        <span>{this.state.configExpanded ? "Hide Config" : "Show Config"}</span>
                        <img src={this.state.configExpanded ? settingsBoxBlue : settingsBoxDark} />
                    </div>
                    <img onClick={() => this.props.onRemove()} className="delete" src={deleteIcon} />
                </HorizontalContainer>
                {
                    this.state.configExpanded &&
                    <div className="config">
                        <div className="arrow"></div>
                        <HorizontalContainer>
                            {this.renderWebhookConfigField("Description", descriptionIcon, webhookInstance.webhook.description, true)}
                            {this.renderWebhookConfigField("Type", typeIcon, webhookInstance.webhook.webhookType.typeName, true)}
                        </HorizontalContainer>
                        <HorizontalContainer>
                            {this.renderWebhookConfigField("URL", linkIcon, webhookInstance.webhook.url, false)}
                            {this.renderWebhookConfigField("Token", keyIcon, webhookInstance.webhook.accessToken ?? "Empty", false)}
                        </HorizontalContainer>
                        {
                            webhookInstance.webhook.configurationParameters &&
                            webhookInstance.webhook.configurationParameters.map((p, idx) =>
                                <HorizontalContainer key={idx}>
                                    {this.renderWebhookConfigField("key", null, p.key, false)}
                                    {this.renderWebhookConfigField("value", null, p.value, false)}
                                </HorizontalContainer>)
                        }
                    </div>
                }
                <FieldArray
                    validateOnChange={false}
                    name={`${this.props.instanceName}.formParameterValues`}
                    render={arrayHelpers => (
                        <div key="params">
                            <div className="parameters">
                                <HorizontalContainer>
                                    <div className="cap-sub-heading">Parameters</div>
                                    <div onClick={() => { arrayHelpers.push({ key: "", value: "", isRequired: false }) }} className="add-parameter">+ Add a Parameter</div>
                                </HorizontalContainer>
                                {
                                    webhookInstance.formParameterValues &&
                                    webhookInstance.formParameterValues.map((p, idx) =>
                                        <div key={idx}>
                                            <HorizontalContainer className="remove-parameter">
                                                <span className="left" />
                                                <div>{p.key ? p.key : "Parameter"}</div>
                                                <span className="right" />
                                                {
                                                    p.isRequired ?
                                                        <HorizontalContainer className="required">
                                                            <div>Required</div>
                                                            <img src={requiredStarIcon} />
                                                        </HorizontalContainer>
                                                        :
                                                        <img src={removeParameterIcon} onClick={() => { arrayHelpers.remove(idx) }} />
                                                }
                                            </HorizontalContainer>
                                            <HorizontalContainer>
                                                <TextField
                                                    className="parameter-field left"
                                                    name={`${this.props.instanceName}.formParameterValues.${idx}.key`}
                                                    value={p.key}
                                                    disabled={p.isRequired}
                                                    placeholder="Enter key"
                                                    label={`Key`}
                                                    onChange={this.props.handleChange}
                                                    onSuggestionClick={(suggestion) => this.props.setFieldValue(`${this.props.instanceName}.formParameterValues.${idx}.key`, suggestion)}
                                                    suggestions={suggestions}
                                                    onBlur={this.props.handleBlur} />
                                                <TextField
                                                    className="parameter-field right"
                                                    name={`${this.props.instanceName}.formParameterValues.${idx}.value`}
                                                    value={p.value}
                                                    placeholder="Enter value"
                                                    label={`Value`}
                                                    onChange={this.props.handleChange}
                                                    onBlur={this.props.handleBlur} />
                                            </HorizontalContainer>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    )}
                />
            </div>
        );
    }
}

const webhookConfigFieldStyle = css`
    width: 50%;
    &.extra-margin-top {
        margin-top: 32px;
    }
    .label {
        color: ${color_shades_darkest};
        text-transform: uppercase;
    }
    .value {
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
        overflow-wrap: break-word;
        width: 95%;
    }
    .line-two {
        img {
            margin-right: 12px;
            height: 24px;
            width: 24px;
        }
        margin-top: 12px;
    }
`;

const attachedWebhookStyle = css`
    &.dragging {
        margin-left: -350px;
        margin-top: -50px;
    }
    background: white;
    border: solid 1px ${color_shades_dark};
    border-radius: 8px;
    color: ${color_shades_darkest};
    font-style: normal;
    font-weight: normal;
    margin: 12px 0px;
    overflow: hidden;
    .cap-sub-heading {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${color_shades_darkest};
    }
    .delete {
        width: 40px;
        border-left: 1px solid ${color_shades_dark};
        padding-left: 16px;
        cursor: pointer;
    }
    .top {
        padding: 16px;
    }
    .title {
        font-size: 18px;
        line-height: 24px;
        margin: 4px 4px 0 8px;
    }
    .link-out-icon {
        margin-left: 8px;
        margin-right: auto;
        img {
            width: 20px;
            margin-top: 6px;
        }
    }
    .show-config {
        font-size: 14px;
        line-height: 20px;                
        display: flex;
        flex-direction: row;
        cursor: pointer;
        width: 124px;
        span {
            padding:6px 0px 4px 8px;
        }
        img {
            padding: 4px;
            margin-left: auto;
        }
        &.expanded {
            color: ${color_colors_ocean};
            background: ${color_variants_ocean_light_2};
            border-radius: 8px;
        }
    }
    .config {
        background: ${color_shades_lighter};
        width: 100%;
        padding: 32px;
        position: relative;
        border-top: 1px solid ${color_shades_dark};
        .arrow {            
            border-left: solid 1px ${color_shades_dark};
            background: ${color_shades_lighter};
            border-top: solid 1px ${color_shades_dark};
            transform: rotate(45deg);
            position: absolute;         
            width: 9px;
            height: 9px;
            top: -5px;
            right: 122px;
        }
    }

    .remove-parameter {
        margin: 16px 0px 16px 0px;
        display: flex;
        flex-direction: row;
        font-style: normal;
        font-weight: normal;
        color: ${color_shades_darkest};
        div {
            font-size: 16px;
            line-height: 24px;
            display: flex;
            flex-direction: row;
        }
        img {
            cursor: pointer;
        }
        .required {
            font-size: 14px;
            line-height: 20px;
            img {
                margin: 2px 0 0 8px;
            }
        }
        span {
            border-bottom: solid 1px ${color_shades_dark};
            margin-bottom: 10px;
            &.left {
                width: 10px;
                margin-right:8px;
            }
            &.right {
                margin-left: 8px;
                flex-grow: 1;
                margin-right: 8px;
            }
        }
    }   

    .parameters {
        border-top: 1px solid ${color_shades_dark};
        padding: 24px;
        .parameter-field {
            margin-left: 0px;
            &.left {
                margin-right: 8px;
            }
            &.right {
                margin-left: 8px;
            } 
        }   
        .add-parameter {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: ${color_colors_ocean};
            margin-left: auto;
            cursor: pointer;
        }
    }

    .webhook-priority {
        display: flex;
        flex-direction: row;
        margin-top: 3px;
        img {
            margin-right: 8px;
        }
        span {
            height: 24px;
            width: 26px;
            text-align: center;
            padding-top: 4px;
            margin-top: 2px;
            border-radius: 50%;
            color: ${color_shades_darkest};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            border: 1px solid ${color_shades_dark};
        }
    }

`;

export default AttachedWebhookCard;